<script setup>
import FooterSection from "./FooterSection.vue";
import HeaderSection from "./HeaderSection.vue";
import GetInTouchSection from "./GetInTouch.vue";
import ClientTestimonialSection from "./ClientTestimonial.vue";

import { onMounted } from "vue";
import AOS from "aos";

onMounted(() => {
  AOS.init();
});
</script>

<template>
  <HeaderSection />
  <!-- ======= Hero Section ======= -->
  <section
    id="hero"
    class="home"
    ref="home"
    data-aos="fade-up"
    data-aos-delay="200"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-8 order-1 order-lg-1 hero-img pl-5">
          <div class="header-spacer d-none d-md-black"></div>
          <div class="vimeo_section">
            <iframe
              src="https://player.vimeo.com/video/1021971228?autoplay=1&amp;loop=1&amp;title=0&amp;byline=0&amp;portrait=0&amp;muted=1"
              width="100%"
              height="100%"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen=""
              style="position: absolute; top: 0; left: 0"
            ></iframe>
            <!-- https://player.vimeo.com/video/1021971228?autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=1 -->
          </div>
          <div class="header-content">
            <h2>
              Insurance payments
              <br /><span class="cursive">made easy </span>
            </h2>
            <h3 class="highlight">
              Premium funding is a simple, short-term loan that is repaid over
              smaller, regular instalments, giving business more financial
              flexibility and allowing better management of cash flow.
            </h3>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Hero -->
  <main id="">
    <!-- ======= About Us Section ======= -->
    <section id="monument-pillars" class="monument-pillars">
      <div class="container" data-aos="fade-up">
        <div class="row actions">
          <h2 class="text-center mb-4">Why use Monument Premium Funding</h2>
          <div class="col-12 col-md-4 text-center">
            <img src="@/assets/img/experience-pillar.png" alt="" /><br />
            <span class="cursive heading">Experience</span>
            <p>
              We've been around for over 30 years and are one of the largest and
              most experienced premium funders in Australia.
            </p>
          </div>

          <div class="col-12 col-md-4 text-center">
            <img src="@/assets/img/stress-free-pillar.png" alt="" /><br />
            <span class="cursive heading">Stress free solution</span>
            <p>
              We offer a clever solution that takes the stress out of insurance
              payments by allowing you to pay for multiple policies through easy
              instalments.
            </p>
          </div>

          <div class="col-12 col-md-4 text-center">
            <img src="@/assets/img/safeguard-pillar.png" alt="" /><br />
            <span class="cursive heading">Safeguard your business</span>
            <p>
              Our goal is to help business safeguard themselves against
              financial setbacks and ensure they can continue to operate and
              grow.
            </p>
          </div>
        </div>
      </div>
    </section>
    <!-- End About Us Section -->
    <!-- ======= Section Break ======= -->
    <section
      id="home-section-break"
      class="section home section-break"
      ref="home-section-break"
    >
      <div class="container">
        <div class="row text-center" data-aos="fade-up">
          <div class="col">
            <h3 class="p-5 m-5">
              Monument Premium Funding provides secure financing for insurance
              premiums to help businesses manage their cash flow.
            </h3>
          </div>
        </div>
      </div>
    </section>
    <!-- End Section Break -->
    <!-- ======= Backed by Gallagher Section ======= -->
    <section id="gallagher" class="section gallagher" ref="gallagher">
      <div class="container">
        <div class="row" data-aos="fade-up">
          <div class="col-12 col-md-6">
            <h1>Partnering with Gallagher Insurance Brokers</h1>
            <p class="highlight">
              Our service caters to the needs of both small and large business,
              regardless of industry, as well as individuals.
            </p>
            <p>
              We understand that insurance payment plans unlock additional cash
              flow and create financial flexibility, freeing business up to
              address any unexpected challenges that may arise. Our service
              makes it possible for insurance brokers to provide their clients
              with better protection and stability.
            </p>
            <div class="row">
              <div class="col pt-2">
                <a href="https://www.elantis.com.au/" target="_blank">
                  <img
                    src="@/assets/img/elantis-logo.jpg"
                    alt="Elantis Premium Funding"
                  />
                </a>
              </div>
              <div class="col pt-2">
                <a href="https://www.ajg.com/au/" target="_blank">
                  <img
                    src="@/assets/img/gallagher-logo.jpg"
                    alt="AJ Gallagher"
                  />
                </a>
              </div>
            </div>
          </div>
          <div class="col d-sm-none d-none d-md-block"></div>
          <div class="col-12 col-md-5 d-none d-md-block">
            <img
              src="@/assets/img/monument-backed-by-gallagher.png"
              alt="Elantis Premium Funding"
            />
          </div>
        </div>
      </div>
    </section>
    <!-- End Gallagher Section -->
    <ClientTestimonialSection />

    <GetInTouchSection />
  </main>
  <!-- End #main -->

  <FooterSection />
</template>
